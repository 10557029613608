import React from 'react'
import { ContentHeader } from './style'

const Hero = () => {
  return (
    <ContentHeader>
      <div className='container'>
        <div className='col-md-9 col-lg-7 px-0'>
          <div className='d-flex align-items-center mb-3'>
            <span className='text-white fs-sm-18 fs-16 lh-20 fw-300 mb-0'>Inter Invest</span>
          </div>
          <h1 className='text-white fs-30 fs-md-48 fw-600 mb-3'>LCI IGPM</h1>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
