import React from 'react'
import SectionTopics from 'src/components/SectionTopics'
import Link from 'src/components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as URLs from 'config/api/Urls'

const ExcelenteRentabilidade = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = '/pra-voce/investimentos/renda-fixa/mercado-secundario/'

  return (
    <section id='excelente-rentabilidade'>
      <div className='container pt-5 pb-xl-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-8'>
            <h2 className='fs-28 lh-32 fs-md-28 mb-4'>Excelente rentabilidade e segurança em relação à inflação</h2>
            <p className='mb-5'>A LCI indexada ao IGPM é uma modalidade de investimento com prazos a partir de 3 anos e
              ótima rentabilidade. Além da isenção de IR, protege o investidor das oscilações da inflação,
              garantindo ganhos reais. Um investimento seguro, garantido pelo FGC e lastreado pro bens
              imóveis financiados pelo próprio Banco Inter.
            </p>

            <SectionTopics title='É ideal para'>
              <ul className='list-unstyled mt-4'>
                <li>Investidores com perfil conservador</li>
                <li>Quem pode deixar o dinheiro aplicado por muito tempo</li>
                <li>Garantir excelente rentabilidade, sem risco de inflação</li>
                <li>Quem quer ganhar mais que alugando um imóvel</li>
              </ul>
            </SectionTopics>

            <p className='mt-4'>
              A LCI IGPM não tem liquidez diária. Mas se você precisar do valor investido antes do prazo,
              pode contar com
              <Link
                title='Mercado Secundário'
                to={url}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'Excelente rentabilidade e segurança em relação à inflação',
                    element_action: 'click button',
                    element_name: 'Mercado Secundário',
                    redirect_url: URLs.SITE_BASE_URL + url,
                  })
                }}
              > Mercado Secundário
              </Link>.
            </p>
            <p>
              A LCI IGPM <strong>não está disponível</strong> no momento. Quer saber mais ou conhecer
              outras opções para investir?
              <a
                href='https://ajuda.bancointer.com.br/'
                target='_blank' rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'Excelente rentabilidade e segurança em relação à inflação',
                    element_action: 'click button',
                    element_name: 'Solicite o contato de um consultor',
                    redirect_url: 'https://ajuda.bancointer.com.br/',
                  })
                }}
              > Solicite o contato de um consultor
              </a>.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExcelenteRentabilidade
