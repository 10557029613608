import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Header from './sections/header/_index'
import Sidebar from './sections/sidebar/_index'

const EmpresasFinanciamentoImobiliarioComercial = () => {
  return (
    <Layout pageContext={pageContext}>
      <Header />
      <Sidebar />
    </Layout>
  )
}

export default EmpresasFinanciamentoImobiliarioComercial
